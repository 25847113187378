/* variables */
:root {
  --main-bg-color: #000;
  --main-fg-color: #fff;
  --font-header: 'Allura', cursive;
  --font-main: 'Open Sans', sans-serif;
}

/* layout */
html,
body {
  height: 100%;
}

body {
  background: var(--main-bg-color);
  color: var(--main-fg-color);
  font-family: var(--font-main);
  position: relative;
}

footer {
  background: #000;
  border-image: url('../img/hr.jpg') 100% 0 0 0/5px 0 0 0 stretch;
  border-style: solid;
  font-size: 0.85rem;
  padding: 1rem 0;
  position: relative;
}

header {
  padding: 1rem 0;
  position: relative;
  z-index: 100;
}

main {
  border-image: url('../img/hr.jpg') 100% 0 0 0/5px 0 0 0 stretch;
  border-style: solid;
  padding: 1rem 0;
  position: relative;
}

section:not(:first-child) {
  border-image: url('../img/hr.jpg') 100% 0 0 0/2px 0 0 0 stretch;
  border-style: solid;
}

.map {
  opacity: 0.75;
  position: relative;
}

.map > iframe {
  border: 0;
  height: 200px;
  margin: 0;
  width: 100%;
}

/* typography */
a {
  text-decoration: none;
}

h1 {
  font-family: var(--font-header);
  font-size: 4rem;
  text-shadow: 1px 1px 20px var(--main-fg-color), 1px 1px 20px var(--main-fg-color);
}

h2 {
  font-family: var(--font-header);
  font-size: 3rem;
  text-shadow: 1px 1px 20px var(--main-fg-color), 1px 1px 20px var(--main-fg-color);
}

h3 {
  font-size: 1.25rem;
  font-weight: 300;
}

/* forms */
input,
textarea {
  opacity: 0.75;
}

/* cards */
.card-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: 300px;
}

/* carousel */
.carousel-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 90vh;
}

/* modals */
.modal-title {
  font-family: var(--font-header);
  font-size: 2rem;
  text-shadow: 1px 1px 20px var(--main-fg-color), 1px 1px 20px var(--main-fg-color);
}

/* custom elements */
.background {
  background: url('../img/PSX_20210227_134341.jpg') no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  left: 0;
  opacity: 0.25;
  position: fixed;
  top: 0;
  width: 100vw;
}

.gototop {
  bottom: 1rem;
  opacity: 0.3;
  position: fixed;
  right: 1rem;
  z-index: +1;
}

.gototop:hover {
  opacity: 1;
}

.logo {
  background: url('../img/logo_text.png');
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 8rem;
}
